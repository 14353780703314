.WNBackground {
    background-image: url(../assets/Frame13.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 346px;
    width: 100%;


}

.coln {
    background-color: #F4F4F4;
    margin: 20px;
    padding-right: 3em;
}

.coln h5 {
    font-weight: 600;
}

.product {
    background-color: #F4F4F4;
    width: 49%;
}

@media only screen and (max-width: 900px) {
    .product {
        width: 100%;
    }

    .coln {
        padding-right: 1em;
    }
}