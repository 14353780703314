.footer1 {
  border-bottom: #fff solid 1px;
}

.btnfooter {
  background-color: #107898;
  color: #fff;
  font-weight: bold;
}

.btnfooter:hover,
.btnfooter:active {
  background-color: #fff !important;
  color: #000 !important;
  outline: none !important;
}

.footerlink:hover,
.footerlink:active {
  color: #107898 !important;

}

.footeractive:hover,
.footeractive:active {
  color: #107898 !important;

}

.footerlink {
  display: inline-block;
  position: relative;
  color: #107898;
}

.footerlink::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #107898;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footerlink:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}