.carousel .carousel-indicators button {
  background-color: transparent;
  border: none;
  border-radius: 1rem;
  width: 5rem;
  height: 0.3rem;
  border: 0.1rem;
  border-style: solid;
  border-color: white !important;
}

.carousel .carousel-indicators button.active {
  background-color: white;
}

@media (max-width: 767px) {
  .carousel-caption-wrap {
    font-size: 14px;
    height: 100%;
  }

  .carousel-caption-wrap p {
    white-space: normal !important;
    word-wrap: break-word !important;
  }
}