.container {
  max-width: 1400px;
}

.gradient_background_linear {
  background: linear-gradient(89.83deg, #4CC9F0 -3.32%, #3A0CA3 77.08%);
}

.Navlink {
  color: #D9D9D9 !important;
  text-decoration: none;
  padding: 15px;
}

.gradient_background.open {
  background: linear-gradient(89.83deg, #4CC9F0 -3.32%, #3A0CA3 77.08%);
}

.content-container {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.content-container.hidden {
  opacity: 0;
}

.navbar-toggler {
  border-color: #fff;
}


.navbar-toggler:focus,
.navbar-toggler:active {
  border-color: #fff;
  border: none;
}



@media (max-width: 768px) {
  .gradient_background {
    transition: background-color 0.3s ease;
    background: linear-gradient(89.83deg, #4CC9F0 -3.32%, #3A0CA3 77.08%) !important;

  }

  .gradient_background.open {
    background: linear-gradient(89.83deg, #4CC9F0 -3.32%, #3A0CA3 77.08%);
  }

  .navbar-collapse.show {
    max-height: 80vh;
    overflow-y: auto;
  }

}