.gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow-x: hidden;
}

.gallerycarousel {
    display: flex;
    width: 100%;
    height: 20rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: static;
}



.carousel-card {
    flex: 0 0 40%;
    scroll-snap-align: start;
    transition: all 0.5s ease-in-out;
    height: auto;
    margin-right: 1em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2em;
    position: static;
    overflow: hidden;
}

.carousel-card:hover {
    cursor: pointer;
}

.card.body {
    position: relative;
}

.card-title {
    color: #4CC9F0;
}

.card-text {
    color: #fff;
}

@media only screen and (max-width: 900px) {
    .gallerycarousel {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        height: 25rem;
    }

    .carousel-card {
        flex: 0 0 auto;
        margin-right: 1em;
        height: 25rem;
    }

    .card.body {
        position: relative;
    }

    .card-title {
        color: #4CC9F0;
        font-size: large;
    }

    .card-text {
        color: #fff;
        font-size: 12px;
    }

    .carousel-card {
        width: 100%;
        flex: 0 0 80%;
        margin-right: 1rem;
    }
}