.card1 {
  z-index: 1;
  width: 14em;
  height: 17em;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 2em 0em 2em 2em;
  background-image: url("../assets/cust1.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-color: white;
}

.card2 {
  width: 11rem;
  height: 11rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  z-index: 2;
  top: 9.5rem;
  left: 3.2rem;
  border-radius: 2rem 0rem 2rem 2rem;
  background-color: #1E0A4C;
  border-color: #1E0A4C;
  color: #fff;
  font-size: 10px;
}

.cardcontainer:hover {
  transform: scale(1.2);
  transition: transform 0.8s ease;
}

@media (max-width: 768px) {
  .custcontainer {
    overflow-x: auto;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .custcontainer::-webkit-scrollbar {
    display: none;
  }

  .cardcontainer {
    display: inline-block;
    width: auto;
    margin-right: 15px;
    margin-left: 0;
    white-space: normal;
  }

  .custrow {
    flex-wrap: nowrap;
  }
}