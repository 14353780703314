
.servicebg {
   
    background-image: url("../assets/DrivvingGrowthBanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    
  }
  .text-18{
  font-size: 18px;
}
.c-blue-green{
  color: #107898;
}
.c-dark-blue{
  color: #1E0A4C;
}
.card-services:hover{
  border: 1px solid #107898;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.service-hov{
  display: none;
    position: absolute;
  height: 10px;
  width: 100px;
  background-color: #107898;
}
.card-services1{
  height: 530px;

}
.service-btn{
  left: 30%;
  right: 30%;
  bottom: 0;
  top: 470px;
}
  .topn {
    position: fixed;
    /* top: 0; */
    width: 100%;
    z-index: 1000; 
  }
  .servicebg2 {
   
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto; 
  }
  .servicebg1{
    background-color: #1E1E1E;
    overflow-x: hidden;
  }
  .card-services:hover .service-hov {
    display: block;
    
  }
  
  .gb3{
    background-image: url("../assets/fuel.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: auto;
  }
  
  .knowbtn:hover {
background-color: #107898;
  }
  @media (max-width: 1200px) {
    .card-services1{
      height: 560px;
    }
    .service-btn{
      top: 510px;
      left: 20%;
      right: 20%;
  }
}
  @media (max-width: 600px) {
    .card-services1{
      height: auto;
    }
    .card-services .service-hov {
      display: block;
      
    }
    .card-services{
      border: 1px solid #107898;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
  }

  