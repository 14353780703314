.icon {
  margin-left: auto;
}

.bg {
  background-image: url("../assets/imagbg1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 15em;
  width: 100%;
  position: absolute;
}

.col-md-3 a {
  margin: 0;
  cursor: pointer;
}

.col-md-3 a:hover {
  color: #fff !important;
  border-color: #fff !important;
}

.icon {
  transition: transform 0.3s ease;
}

.col-md-3 a:hover .icon {
  transform: rotate(90deg);
}

@media (max-width: 700px) {
  .bg {
    position: relative;
    height: auto;
  }
}