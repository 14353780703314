.card-shadow{
    box-shadow: 0px 4px 4px 0px #00000040;
}
.digital-card1{
    transform: rotate(-10grad); 
    position: absolute;
    left: 10px;
    width: 320px;
    background-color: #107898;
    color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
    top: -10px;
}
.digital-card2{
    transform: rotate(10deg); 
    position: absolute;
    left: 100px;
    width: 320px;
    top: 175px;
    background-color: #107898;
    color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
}
.digital-card3{
    transform: rotate(-10grad); 
    position: absolute;
    left: 350px;
    top: 35px;
    width: 320px;
    background-color: #107898;
    color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
}
.digital-col{
    border: 2px solid #107898;
}
.digital-card-md{
    background-color: #107898;
    color: #fff;
}
.digital-numbers{
    background: linear-gradient(180deg, #4CC9F0 0%, #3A0CA3 100%);
    color: #fff;
    border-radius: 80px;
}
.other-services{
    bottom: 15px;
    left: 20%;
    right: 20%;
}
@media (max-width: 1200px) {
    .digital-card1 {
        left: -10px; 
    }
     .digital-card2 {
        left: 60px; 
    }
    .digital-card3 {
        left: 300px; 
    }
  }