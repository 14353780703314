.gb {

  background-image: url("../assets/DrivvingGrowthBanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;

}

.topn {
  position: fixed;
  /* top: 0; */
  width: 100%;
  z-index: 1000;
}

.agb1 {
  background-color: #F4F4F4;
  overflow-x: hidden;
}

.acard3 {
  overflow: hidden;
}

.acard3 img {
  transition: transform 0.5s ease;
}

.acard3 img:hover {
  transform: scale(1.2);
}

.agb2 {
  background-image: url(../assets/Frame012.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 23rem;

}

.agb3 {
  background-color: #1E0A4C;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.acard1 {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  z-index: 2;
  background-color: #fff;
  border-color: #fff;
}

.acard {
  max-width: 1200px;
}

.acard2 {
  overflow: hidden;
}

.acard2 img {
  transition: transform .6s ease;
}

.acard2:hover img {
  transform: scale(1.3);

}
.gb3 {
  background-image: url("../assets/fuel.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
@media (max-width: 900px) {
  .acard {
    position: relative !important;
    padding: 0 !important;
    margin: 0 0 10px 0 !important;

  }
  .acard1 {
    position: relative !important;
    top: 0 !important;
    z-index: 0;
    transform: translate(0%, 0%) !important;
  }
  .acard2 {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
}

