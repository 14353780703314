.sbg {
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 900px) {
  .sbg {
    font-size: 5px;
  }
}