  .main {
    width: 100%;
  }

  .gb {
    background-image: url("../assets/DrivvingGrowthBanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }

  .topn {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .gb2 {
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }

  .gb1 {
    background-color: #F4F4F4;
    overflow-x: hidden;
  }

  .service a {
    color: #5C5858;
    cursor: pointer;
    margin: 0.5rem 0.5rem 0.5rem 0;
    text-decoration: none;
    position: relative;
    font-size: 18px;
  }

  .service a.active {
    color: white;
    font-weight: bold;
  }

  .gb3 {
    background-image: url("../assets/fuel.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }

  .service a.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(89.83deg, #4CC9F0 -3.32%, #3A0CA3 77.08%);
    font-weight: bold;
  }
  .btn-dark{
    background-color: #1E0A4C;
    color: #fff !important;
  }
  .btn-dark a{
    color: #fff !important;
  }
  .btn-dark:hover a, .btn:active a{
    color: #1E0A4C !important;
  }
  
  .btn-dark:hover, .btn:active{
    background-color: #fff !important;
    color: #1E0A4C !important;
  }
  @media (max-width: 900px) {
    .service {
      flex-direction: column;
      font-size: 14px;
    }
  }