/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.App{
  font-family: "Roboto";
}
/* .card-body a{
    background-color: #D9AFD9;
background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);

}

.container{
    background-color: #D9AFD9;
background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);

} */

.card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  border-color: rgb(31, 120, 152);
  /* box-shadow: -6px 1px 17px -1px rgba(0,0,0,0.7); */
}
.card-faqs:hover{
transform: scale(1.00);
transition: transform 0s ease;

}
body{
  background-color: var(--bs-border-color--transparentt, rgba(245, 245, 245, 0.79)); /* Fallback color */
  }  

.imagesize {

  width: 100%;
  max-width: 200px;
  height:auto;
}
.setimage{
 
  max-width:100%;
  height: auto;
 
 }
 .texts{
  height: 90px;
 }
 
.Atext{
  font-size:12px;
  font-weight: 500;
}
.Btext{
  font-size:20px;
  
  color: rgb(31, 120, 152);
}
.Bbutton{
  background-color: #ece7ff;
    border-color: rgb(31, 120, 152);
    width: 95%;
    height: 50px;
  border-radius: 25px;
}
.Abutton{
 width:90%;
 height: 50px;
 border-radius: 25px;
}
.typewrite{
  color: rgb(31, 120, 152);
  height: 75px;
}
.logo{
  height: 24px;
}
.textbox{
  height: 50px;
}

