
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.password-generator{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;    padding: 10px;
    border-radius: 15px;

}

.accordion-header {
    background-color: #fff ; 
    color: black; /* Color when collapsed */
    cursor: pointer;
    transition: background-color 0.6s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0.5px solid rgb(16, 120, 152);
}
.accordion-header:hover{
    border: 0.5px solid rgb(16, 120, 152);
}
.password-generate-heading{
    color: rgb(16, 120, 152);
}
.icon{
    background: transparent;
}
.card-body-faq{
    background-color: rgb(16, 120, 152); /* Color when expanded */
    color: white;
}
.card-faqs:hover{
    border: none !important;
    transition: none !important;
}