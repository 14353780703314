.topics{
    background: linear-gradient(89.83deg, #4CC9F0 -3.32%, #3A0CA3 77.08%);
}
.card-benifits {
    background: linear-gradient(180deg, #4CC9F0 0%, #3A0CA3 100%);
    border: none !important;
}
.timeline {
    list-style: none;
    padding: 0px 0px 0px 0px;
    position: relative;
}
.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    z-index: 2;
}
.timeline-item {
    margin-bottom: 20px;
    position: relative;
}
.timeline-item:before,
.timeline-item:after {
    content: " ";
    display: table;
}
.timeline-item:after {
    clear: both;
}
.timeline-item:before,
.timeline-item:after {
    content: ' ';
    display: table;
}
.timeline-item:after {
    clear: both;
}
.timeline-badge {
    z-index: 1000;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -15px;
    background-color: #107898;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}
.timeline-item .timeline-panel {
    width: 46%;
    float: left;
    background-color: #1E0A4C;
    border: 1px solid #1E0A4C;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}
.timeline-item .timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    color: #1E0A4C;
    border-top: 15px solid transparent;
    border-left: 15px solid #1E0A4C;
    border-right: 0 solid #1E0A4C;
    border-bottom: 15px solid transparent;
    content: ' ';
}
.timeline-item .timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    color: #1E0A4C;

    border-top: 14px solid transparent;
    border-left: 14px solid #1E0A4C;
    border-right: 0 solid #1E0A4C;
    border-bottom: 14px solid transparent;
    content: ' ';
}
.timeline-item.left .timeline-panel {
    float: right;
    left: 2px;
}
.timeline-item.left .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}
.timeline-item.left .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}
.timeline-item.right .timeline-panel {
    float: left;
   
}
.internship-numbers1{
    color: #000;
    border-radius: 90px;
    border: 1px solid hsl(194, 81%, 33%);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
 li::marker{
color: #107898;
}
.roles{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: #1E0A4C; 
    color: #fff;
}
@media (max-width: 767px) {
    .timeline-badge {
        left: 0;
        margin-left: 0;
        display: none;
    }
    .timeline-item {
        margin-bottom: -30px;
    }
}