


.Cbutton, .Cbutton:hover, .Cbutton:active{
    background-color: #1E0A4C;
    border: 2px solid #1E0A4C ;
    border-color: #1E0A4C;
    width: 200px;
    height: 45px;
     border-radius: 25px;
     color: white;
  }
  .Back{
    border: 2px solid #fff ;
    border-radius: 8px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    display: flex;
   height:45px;
   width: 45px;
  }
  .cardtext{
    text-align: left;
    font-size: 20px;
    color: #fff;
  }
  .cardb{
  border: none !important; 
  background: linear-gradient(180deg, #4CC9F0 0%, #3A0CA3 100%);
}
    

  .bottom{
text-align: center;

  }
 .bottoml{
  font-size:14px;
 }
 
 
 .internship-icon{
  color: #fff;
 }