.contactbg {
  background-color: #010325;
  color: #fff;
}

.form-group {
  position: relative;
}

.form-group input:focus {
  outline: 0px !important;
  box-shadow: none !important;
  font-size: 14px;
}

.form-group textarea:focus {
  outline: 0px !important;
  box-shadow: none !important;
  font-size: 14px;
}

.form-group Select:focus {
  outline: 0px !important;
  box-shadow: none !important;
  font-size: 14px;
}

.label-active {
  position: absolute;
  z-index: 100;
  top: -10px;
  background-color: #fff;
  left: 20px;
}

.topn {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

input::placeholder {
  color: #ccc;
}

input:focus::placeholder {
  color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: white !important;
  border-color: #ced4da !important;
  -webkit-text-fill-color: #495057 !important;
  box-shadow: none !important;
}

input:focus {
  outline: none;
}

.dropdown-icon {
  position: absolute;
  top: 20%;
  right: 10px;
  pointer-events: none;
}

.rotate {
  transform: rotate(180deg);
}

::placeholder {
  color: #000 !important;
  font-size: 14px;
}
.conatct-bg{
background-image: url(../assets/Contact_background.png);
background-repeat: no-repeat;
}